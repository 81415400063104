.policy-pdf ul {
  list-style: none; /* Remove default bullets */
  margin: 0;
  padding: 0;
}

.policy-pdf ul li {
  line-height: 1.5;
  padding-left: 25px;
}

.policy-pdf ul li::before {
  content: "•";
  font-size: 18px;
  color: black;
  line-height: 1;
  margin-right: 10px;
  position: absolute;
  left: 0;
}

.table-header th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}

.table-container {
  max-height: 700px;
  overflow-y: auto;
}

/* used for instrument-log */
.instrument-log {
  border: 1px solid #ddd;
}

.mobile-calendar .fc .fc-toolbar-title {
  font-size: 0.9rem;
  font-weight: bold;
}

.mobile-calendar .fc-event {
  touch-action: manipulation;
  cursor: pointer;
}

.mobile-calendar .fc-view {
  touch-action: auto !important;
}
