body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 80px;
}
@media (min-width: 48em) {
  /* md breakpoint in Chakra UI */
  body {
    padding-top: 160px; /* For desktop */
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.chakra-checkbox__control {
  background-color: white;
}

/* Change cursor when hovering over event on calendar */
.fc-event {
  cursor: pointer;
}

/* Hover over the ER button reads Extended Reservation */
.fc-multipleReservationsButton-button::before {
  content: "Extended Reservation";
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.fc-multipleReservationsButton-button:hover::before {
  visibility: visible;
  opacity: 1;
}
